import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'

const AboutUsContent = () => {
    return (
        <section className="about-area ptb-10">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    About Perisun
                                </span>
                                <h2>Dynamic and Innovative</h2>
                                <p> Perisun is a dynamic and innovative company that is dedicated to providing transformative
                                    and enriching experiences for individuals seeking to reconnect with themselves and the world
                                    around them.</p>

                                <p> With a focus on excellence, Perisun is dedicated to meeting the needs of its guests while ensuring
                                    that every aspect of its services is carried out with the utmost professionalism and integrity.
                                    Our company is founded on the belief that everyone deserves the opportunity to take a step back from the stresses
                                    of daily life and engage in personal growth and reflection.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p> Our retreats are carefully crafted to provide a balance of relaxation, self-discovery, and adventure.
                                        We offer a variety of programs, including yoga retreats, meditation retreats, and wellness retreats, all designed
                                        to help individuals find inner peace and clarity.  </p>
                                    <p>We are committed to providing a safe and supportive environment for our guests. Our team of experienced instructors
                                        and facilitators are dedicated to creating a nurturing and compassionate atmosphere, where guests can feel comfortable
                                        exploring their inner selves.  </p>
                                    <p>We are also passionate about sustainability and environmental responsibility. That's why we work hard to ensure that
                                        all of our retreats are eco-friendly and leave a minimal impact on the environment. From our choice of accommodations
                                        to our food choices, we prioritize sustainable practices in everything we do.  </p>
                                    <p>Our mission is to empower individuals to connect with themselves and the natural world, fostering a deeper sense of
                                        purpose and meaning in their lives. We believe that everyone deserves the opportunity to experience the
                                        transformative power of retreats, and we are committed to making this experience accessible to all.  </p>
                                    <p>Overall, Perisun is a company that is driven by a commitment to excellence, innovation, and sustainability.
                                        With a focus on meeting the needs of our clients and making a positive impact on the world around us,
                                        we are confident that we can deliver exceptional service and comfort for our guests. </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;