import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
const Perisun = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="About Perisun"
                homePageText="Home"
                homePageUrl="/"
                activePageText="About Perisun"
            />
            <AboutUsContent />
            <Footer />
        </Layout>
    );
}

export default Perisun